import React from 'react';

import * as styles from '../../styles/modules/homepage-copy.module.scss';

const Copy = () => {
    return (
        <div className={styles.copy}>
            <h2>Love Where You Live? Renovate!</h2>
            <p>Is your home starting to feel cramped? Do you need more space to work, play or relax? Instead of packing up and moving to a new home, talk to us about a custom home addition. Our builders can expand your home in any way you'd like; we'll meet with you on-site to assess your property and help you design your new space and get a little extra breathing room in your home.</p>
            <p>No matter what type of project you have in mind, we listen to our clients' requirements to help them acheive beautiful results. When you combine execllent design with high-quality materials and expert craftsmanship, you are guaranteed to see your vision come to life.</p>
            <p>Our goal is to exceed the expectations of our clients – not only with the quality of the home, but the quality of the experience! <em>Building your dream home should not be a nightmare.</em> Remember, custom is part of our name. All of our projects are 100% custom-built. At Alouf Custom Builders, you're only limited by your imagination. We will work with you to design and build the project of your dreams.</p>
        </div>
    );
};

export default Copy;
