import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from '../../styles/modules/homepage-parallax.module.scss';

const Parallax = () => {
    return (
        <div className={styles.parallax}>
            <div className={[styles.accent, "copy-width"].join(' ')}>We love new construction. What could be more ideal than starting with a blank sheet of paper and designing your dream project? We meet and work with our clients extensively to design their one-of-a-kind projects from the ground up. At Alouf Custom Builders, our work originates from our passion for quality, workmanship and artistry.</div>
        </div>
    );
};

export default Parallax;
