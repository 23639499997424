import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Jumbotron } from 'react-bootstrap';

import * as styles from '../../styles/modules/homepage-hero.module.scss';
import routes from '../../constants/routes';

const Hero = () => {
    return (
        <Jumbotron className={styles.hero} fluid>
            <StaticImage alt="hero image" src="../../../static/assets/jumbotron.jpg" placeholder="blurred" layout="fullWidth" />
            <div className={styles.ctaContainer}>
                <div className={styles.logoContainer}>
                    <StaticImage alt="Alouf Custom Builders logo" src="../../images/logo.svg" placeholder="blurred" layout="fixed" width={200} height={150} />
                </div>
                <Link className="btn btn-primary" to={routes.about}>Meet Our People</Link>
                <Link className="btn btn-primary" to={routes.gallery}>See Our Work</Link>
            </div>
        </Jumbotron>
    );
};

export default Hero;