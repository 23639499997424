import React from 'react';

import * as styles from '../../styles/modules/homepage-review.module.scss';

const Review = () => {
    return (
        <div className={styles.review}>
            <p>Building our home was not the headache and stress that I had heard that building a house would be. My family attributes that to the professionalism of Alouf Custom Builders. Our home turned out to be so much more than we had envisioned when we started and we coudn't be happier with the quality of work we received from Mark, Chris, Crystal and Hollie.</p>
            <p className={styles.byline}>&mdash; Randy B., Riner, VA</p>
        </div>
    );
};

export default Review;
