import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from '../../styles/modules/homepage-btf.module.scss';

const BelowTheFold = () => {
    return (
        <div className={styles.belowTheFold}>
            <h1>Building the Future, Renewing the Past</h1>
            <div className={styles.accentBlock}>
                <h2>Let's Face It</h2>
                <p>A construction project can be a rewarding experience – or a maddening one. It's all going to depend on your builder.</p>
                <p>Construction is in our blood. We have years of experience helping our customers create their dream home. At Alouf Custom Builders, we know that any construction project – no matter how big or how small – can be a nightmare if you don't know the right builder.</p>
            </div>
            <div className={styles.imageTrio}>
                <div className={styles.imageWrapper}>
                    <StaticImage alt="" src="../../../static/assets/row1.jpg" placeholder="blurred" />
                    <div className={styles.overlay}>Personalized Service</div>
                </div>
                <div className={styles.imageWrapper}>
                    <StaticImage alt="" src="../../../static/assets/row2.jpg" placeholder="blurred" />
                    <div className={styles.overlay}>Experience &amp; Integrity</div>
                </div>
                <div className={styles.imageWrapper}>
                    <StaticImage alt="" src="../../../static/assets/row3.jpg" placeholder="blurred" />
                    <div className={styles.overlay}>Quality &amp; Craftsmanship</div>
                </div>
            </div>
        </div>
    );
};

export default BelowTheFold;
