import React from 'react';

// Components
import Layout from '../components/layout';
import Hero from '../components/homepage/hero';
import BelowTheFold from '../components/homepage/below-the-fold';
import Parallax from '../components/homepage/parallax';
import Copy from '../components/homepage/copy';
import Review from '../components/homepage/review';
import Contact from '../components/sections/contact';

import routes from '../constants/routes';

const HomePage = () => {
    return (
        <Layout slug={routes.home} specialTitle="Alouf Custom Builders | The Roanoke Valley's Premier Custom Builder &amp; Contractor">
            <Hero />
            <BelowTheFold />
            <Parallax />
            <div className="copy-width">
                <Copy />
                <Review />
                <Contact>
                    <h3>Ready to Start Working on Your Dream Home?</h3>
                    <p>Fill out this form and we'll get back to you in a jiffy! We look forward to working with you.</p>
                </Contact>
            </div>
        </Layout>
    );
};

export default HomePage;
